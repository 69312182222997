import styled from 'styled-components'

// eslint-disable-next-line no-restricted-imports
import { Link } from '@fortum/elemental-ui'

import { StyledLink, type StyledLinkProps } from '../../StyledLink'
import type { LinkStyleProps } from '../../StyledLink/styled'
import { linkMixins } from '../../StyledLink/styled'
import { useLinkColor } from '../LinkColorProvider'

/**
 * Should be used only in special cases.
 * For phone and email hrefs. Otherwise for some reason Next router taking it as a path and tries to update the route.
 */
const ElementalStyledLink = styled(Link)<LinkStyleProps>`
  ${linkMixins}
`

const isPhoneOrEmailUri = (uri: string) => {
  const protocolRegex = /^(mailto:|tel:)/i

  return protocolRegex.test(uri)
}

export const RichTextHyperlink = (props: StyledLinkProps) => {
  const color = useLinkColor()

  if (props.href && isPhoneOrEmailUri(props.href as string)) {
    return (
      <ElementalStyledLink {...props} color={color}>
        {props.children}
      </ElementalStyledLink>
    )
  }

  return <StyledLink {...props} color={color} />
}
