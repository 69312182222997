import type { Theme as ElementalTheme } from '@fortum/elemental-ui'
import { generateLegacyTheme } from '@fortum/elemental-ui'

import type { CosColor } from '@/shared/utils/colors'
import { colors, darkColors, lightColors } from '@/shared/utils/colors'

export type Theme = ElementalTheme & {
  colors: ElementalTheme['colors'] & typeof colors & CosColor
}

const createTheme = (elementalTheme: ElementalTheme, designColors: CosColor): Theme => ({
  ...elementalTheme,
  colors: {
    ...colors,
    ...elementalTheme.colors,
    ...designColors,
  },
})

export const generateElementalTheme = (isDarkMode: boolean): Theme => {
  const elementalTheme = generateLegacyTheme({
    colorScheme: 'modern',
    baseTheme: isDarkMode ? 'dark' : 'light',
    borderRadius: 0,
  })

  return createTheme(elementalTheme, isDarkMode ? darkColors : lightColors)
}

export const getElementalTheme = (): Theme => generateElementalTheme(false)
